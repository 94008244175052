
// MENU //
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';

// PAINTINGS //
export const GET_ALL_PAINTINGS = 'GET_ALL_PAINTINGS';
export const GET_PAINTING_IDS = 'GET_PAINTING_IDS';
export const GET_ONE_PAINTING = 'GET_ONE_PAINTING';
export const SET_PAINTINGS_LOADING = 'SET_PAINTINGS_LOADING';

// BASKET
export const BASKET_OPEN = 'BASKET_OPEN';
export const BASKET_CLOSE = 'BASKET_CLOSE';
export const BASKET_TOGGLE = 'BASKET_TOGGLE';
export const BASKET_ITEM_ADD = 'BASKET_ITEM_ADD';
export const BASKET_ITEM_LESS = 'BASKET_ITEM_LESS';
export const BASKET_ITEM_MORE = 'BASKET_ITEM_MORE';

// PAINTINGS ADMIN //
export const UPDATE_PAINTING = 'UPDATE_PAINTING';
export const DELETE_PAINTING_BY_ID = 'DELETE_PAINTING_BY_ID';
export const UPDATE_PAINTING_FILENAMES = 'UPDATE_PAINTING_FILENAMES';

// ADMIN AUTH //
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// ERRORS
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// REQUEST
export const SET_REQUEST = 'SET_REQUEST';
export const SET_REQUEST_SUCCESS = 'SET_REQUEST_SUCCESS';
export const SET_REQUEST_FAIL = 'SET_REQUEST_FAIL';
export const SET_REQUEST_RESPONSE = 'SET_REQUEST_RESPONSE';
export const SET_REQUEST_NULL = 'SET_REQUEST_NULL';
export const SET_REQUEST_ERROR = 'SET_REQUEST_ERROR';
export const CLEAR_REQUEST_ERROR = 'CLEAR_REQUEST_ERROR';

// NOTIFICATIONS - Things that inform the user -  a new feature.
export const NEW_ERROR_NOTIFICATION = 'NEW_ERROR_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const NEW_SUCCESS_NOTIFICATION = 'NEW_SUCCESS_NOTIFICATION';
export const ADD_LOADER = 'ADD_LOADER';
export const REMOVE_LOADER = 'REMOVE_LOADER';
