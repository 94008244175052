import React from 'react';

const Footer = () => {

    return (<>
        <footer className='info-footer'>
            <div className=''>
                Website design by Max Silin. 
            </div>    
        </footer>   
    </>)
}

export default Footer;