
export const USE_BASKET = false;
export const USE_CAPTCHA = false;

export const contact_email = "2";
export const contact_phone_number = "079079079079...";
export const contact_instagram = "instagram";
export const contact_facebook = "facebook";

export const zoom = 2.5;

export const showCasePaintingIds = ["5ed0fdcd7179500c80240e48", "5f11f2b8c0a9772ae8580ce9", "", "62b231f3b7a4b83750f3f616"];
